.main-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  /* color: rgb(0,175,145); */
  color: white;
  /* border: 2px solid #00af91; */
  border-radius: 15px;
  min-height: 60vh;
  padding: 1.7%;

  /* background-color: #252525; */
  background-color: rgb(30, 30, 30);
}
