.work_img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 600px) {
    .work_img {
        width: 40%;
    }
}

